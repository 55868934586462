import {useParams, Link} from '@remix-run/react';
import {useTranslation} from 'react-i18next';
import {
  ProjectSummary,
  PROJECT_SUMMARY_IMAGE_HEIGHT,
  PROJECT_SUMMARY_IMAGE_WIDTH,
} from '~/features/shared';

interface ProjectSummaryCard {
  project: ProjectSummary;
}

export function ProjectSummaryCard({project}: ProjectSummaryCard) {
  const cateogoryPath = `/proyectos/${project.category}`;
  const projectPath = `${cateogoryPath}/${project.handle}`;

  const params = useParams();

  const state = {
    from: params.category ? 'category' : 'projects',
  };

  const {t} = useTranslation();

  return (
    <article className="flex flex-col items-start p-4 bg-white rounded-lg">
      <Link
        to={projectPath}
        state={state}
        className="overflow-hidden relative mb-4 w-full bg-gray-500 rounded-lg h-[15rem]"
      >
        {project.image ? (
          <img
            className="object-cover w-full h-full"
            height={PROJECT_SUMMARY_IMAGE_HEIGHT}
            width={PROJECT_SUMMARY_IMAGE_WIDTH}
            alt={project.image.image.altText}
            src={project.image.image.url}
          />
        ) : null}
      </Link>

      <div className="flex flex-col flex-1 items-start">
        <div className="flex-1">
          {project.category ? (
            <p className="mb-2 text-sm font-medium tracking-widest uppercase text-blue-dark decoration-blue-dark">
              <Link className="hover:underline" to={cateogoryPath}>
                {t(project.category)}
              </Link>
            </p>
          ) : null}

          <h2 className="font-bold uppercase">
            <Link className="hover:underline" to={projectPath} state={state}>
              {project.title}
            </Link>
          </h2>

          {project.installationLocation ? (
            <p className="text-sm font-medium text-gray-500">
              {project.installationLocation}
            </p>
          ) : null}
        </div>

        {/* <div className="flex justify-between">
          <button
            className="flex gap-2 items-center font-medium text-gray-500"
            style={{
              fontSize: '0.8rem',
            }}
          >
            <span>
              <Trans i18nKey="compartir" />
            </span>

            <Image
              width={16}
              height={16}
              loading="eager"
              src="https://cdn.shopify.com/s/files/1/0745/9438/6205/files/projects-share-icon.svg?v=1685408237"
              alt="Icono de compartir"
            />
          </button>

          <button
            className="flex gap-2 items-center p-2 font-medium text-gray-500 rounded-lg border border-gray-200"
            style={{
              fontSize: '0.8rem',
            }}
          >
            <span>{project.likes}</span>

            <Image
              width={16}
              height={16}
              loading="eager"
              src="https://cdn.shopify.com/s/files/1/0745/9438/6205/files/projects-heart-icon.svg"
              alt="Icono de compartir"
            />
          </button>
        </div> */}
      </div>
    </article>
  );
}
